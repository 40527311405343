import { Tracker as UpstreamTracker } from '~/tracking/tracker';
import { LOAD_ACTION_ATTR_SELECTOR } from './constants';
import { dispatchEvent } from './dispatch_event';
import { getEventHandlers, createEventPayload } from './utils';

const Tracker = {
  nonInitializedQueue: [],
  initialized: false,
  definitionsLoaded: false,
  definitionsManifest: {},
  definitionsEventsQueue: [],
  definitions: [],
  ALLOWED_URL_HASHES: ['#diff', '#note'],
  /**
   * (Legacy) Determines if tracking is enabled at the user level.
   * https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/DNT.
   *
   * @returns {Boolean}
   */
  trackable() {
    return !['1', 'yes'].includes(
      window.doNotTrack || navigator.doNotTrack || navigator.msDoNotTrack,
    );
  },
  /**
   * Find out whether posthog script is introduced
   *
   * @returns {Boolean}
   */
  posthogEnabled() {
    return window.posthog && typeof window.posthog.init === 'function' && Tracker.trackable();
  },

  /**
   * Determines if Snowplow is available/enabled.
   *
   * @returns {Boolean}
   */
  enabled() {
    if (process.env.NODE_ENV === 'test') {
      return UpstreamTracker.enabled();
    }
    return Tracker.posthogEnabled();
  },

  /**
   * Dispatches a structured event per our taxonomy:
   * https://docs.gitlab.com/ee/development/snowplow/index.html#structured-event-taxonomy.
   *
   * If the library is not initialized and events are trying to be
   * dispatched (data-attributes, load-events), they will be added
   * to a queue to be flushed afterwards.
   *
   * If there is an error when using the library, it will return ´false´
   * and ´true´ otherwise.
   *
   * @param  {...any} eventData defined event taxonomy
   * @returns {Boolean}
   */
  event(...eventData) {
    if (!Tracker.posthogEnabled()) {
      return false;
    }

    if (!Tracker.initialized) {
      Tracker.nonInitializedQueue.push(eventData);
      return false;
    }

    return dispatchEvent(...eventData);
  },

  /**
   * Dispatches a structured event with data from its event definition.
   *
   * @param {String} basename
   * @param {Object} eventData
   * @returns {Boolean}
   */
  definition(basename, eventData = {}) {
    if (!Tracker.enabled()) {
      return false;
    }

    if (!(basename in Tracker.definitionsManifest)) {
      throw new Error(`Missing Snowplow event definition "${basename}"`);
    }

    return Tracker.dispatchFromDefinition(basename, eventData);
  },

  /**
   * Builds an event with data from a valid definition and sends it to
   * Snowplow. If the definitions are not loaded, it pushes the data to a queue.
   *
   * @param {String} basename
   * @param {Object} eventData
   * @returns {Boolean}
   */
  dispatchFromDefinition(basename, eventData) {
    if (!Tracker.definitionsLoaded) {
      Tracker.definitionsEventsQueue.push([basename, eventData]);

      return false;
    }

    const eventDefinition = Tracker.definitions.find((definition) => definition.key === basename);

    return Tracker.event(
      eventData.category ?? eventDefinition.category,
      eventData.action ?? eventDefinition.action,
      eventData,
    );
  },

  /**
   * Dispatches any event emitted before initialization.
   *
   * @returns {undefined}
   */
  flushPendingEvents() {
    Tracker.initialized = true;

    while (Tracker.nonInitializedQueue.length) {
      dispatchEvent(...Tracker.nonInitializedQueue.shift());
    }
  },

  /**
   * Attaches event handlers for data-attributes powered events.
   *
   * @param {String} category - the default category for all events
   * @param {HTMLElement} parent - element containing data-attributes
   * @returns {Array}
   */
  bindDocument(category = document.body.dataset.page, parent = document) {
    if (!Tracker.enabled() || parent.trackingBound) {
      return [];
    }

    // eslint-disable-next-line no-param-reassign
    parent.trackingBound = true;

    const handlers = getEventHandlers(category, (...args) => Tracker.event(...args));
    handlers.forEach((event) => parent.addEventListener(event.name, event.func));

    return handlers;
  },

  /**
   * Attaches event handlers for load-events (on render).
   *
   * @param {String} category - the default category for all events
   * @param {HTMLElement} parent - element containing event targets
   * @returns {Array}
   */
  trackLoadEvents(category = document.body.dataset.page, parent = document) {
    if (!Tracker.enabled()) {
      return [];
    }

    const loadEvents = parent.querySelectorAll(LOAD_ACTION_ATTR_SELECTOR);

    loadEvents.forEach((element) => {
      const { action, data } = createEventPayload(element);
      Tracker.event(category, action, data);
    });

    return loadEvents;
  },

  /**
   * Only for preventing null invoking
   */
  enableFormTracking() {},

  /**
   * Only for preventing null invoking
   */
  setAnonymousUrls() {},
};

const ExportTracker = window.posthog ? Tracker : UpstreamTracker;

export { ExportTracker as Tracker };
